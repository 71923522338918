import {
  CesStep,
  ChatStep,
  FileInputStep,
  OptionsStep,
  StepType,
  TabularDataStep,
  TextStep,
} from './index'
import { MultiChoiceStep } from './MultiChoiceStep'
import { SimpleUserInputStep } from './SimpleUserInputStep'
import { MultiInputComponentStep } from './MultiInputStep'
import { SubmittedCesStep, SubmittedTabularDataStep } from '@typedef/chatSteps'
import { SubmittedTextToChoiceStep, TextToChoiceStep } from '@typedef/chatSteps/TextToChoiceStep'

export const isTextStep = (step: ChatStep): step is TextStep => 'message' in step && 'end' in step

export const isUserStep = (step?: ChatStep): boolean => {
  return step !== undefined && 'user' in step && step.user
}

export const isCesStep = (step?: ChatStep): step is CesStep => {
  return step !== undefined && step.type === StepType.CES_STEP
}

export const isSubmittedCesStep = (step: ChatStep): step is SubmittedCesStep => {
  return isCesStep(step) && 'submittedValue' in step
}

export const isTextToChoiceStep = (step?: ChatStep): step is TextToChoiceStep => {
  return step !== undefined && step.type === StepType.TEXT_TO_CHOICE_STEP
}

export const isSubmittedTextToChoiceStep = (step: ChatStep): step is SubmittedTextToChoiceStep => {
  return isTextToChoiceStep(step) && 'submittedValue' in step
}

export const isTabularDataStep = (
  step?: ChatStep,
): step is TabularDataStep | SubmittedTabularDataStep => {
  return step !== undefined && step.type === StepType.TABULAR_DATA_STEP
}

export const isBasicUserInputStep = (
  step?: ChatStep,
): step is SimpleUserInputStep | FileInputStep => {
  return (
    step !== undefined &&
    (step.type === StepType.INPUT_STEP || step.type === StepType.FILE_INPUT_STEP)
  )
}

export function isOptionsStep(step: ChatStep | undefined): step is OptionsStep {
  return step !== undefined && step.type === StepType.OPTIONS_STEP
}

export function isMultipleChoiceStep(step?: ChatStep): step is MultiChoiceStep {
  return step !== undefined && step.type === StepType.MULTIPLE_CHOICE_STEP
}

export const isMultiInputComponentStep = (
  step: ChatStep | undefined,
): step is MultiInputComponentStep => {
  return step !== undefined && 'sections' in step
}
