import React from 'react'
import {
  TextToChoiceStep as TextToChoiceStepType,
  TextToChoiceSubmitValue,
} from '@typedef/chatSteps/TextToChoiceStep'
import { SimulatorOptions } from '@utils/simulator/inputs'
import { StepOption } from '@typedef/chatSteps/OptionsStep'
import { ChatOptions } from '@components/ChatOptions/ChatOptions'
import { ChatDataTestId, StepType } from '@typedef/chatSteps'
import { TEXT_TO_CHOICE_NO_MATCH_LABEL } from '@constants/input'

export interface Props {
  step: TextToChoiceStepType
  sendValue: (value: TextToChoiceSubmitValue, stepInput?: SimulatorOptions, label?: string) => void
}

export const TextToChoiceOptions = ({ step, sendValue }: Props) => {
  const options = step.options

  const onSubmit = ({ selectedChoiceId }: TextToChoiceSubmitValue) => {
    const choiceIndex = options.findIndex((option) => option.value === selectedChoiceId)

    sendValue(
      {
        selectedChoiceId,
      },
      options.length > 1
        ? {
            type: StepType.OPTIONS_STEP, // TODO: NEG-1075 this is going to be T2C step type
            value: {
              choiceIndex,
            },
          }
        : undefined,
    )
  }

  return (
    <ChatOptions
      choices={options.filter((option) => option.label !== TEXT_TO_CHOICE_NO_MATCH_LABEL)}
      onChipClick={(option: StepOption) => onSubmit({ selectedChoiceId: option.value })}
      dataTestId={ChatDataTestId.TEXT_TO_CHOICE_BUTTON_CONTAINER}
    />
  )
}
