import { TextToChoiceStep, TextToChoiceSubmitValue } from '@typedef/chatSteps/TextToChoiceStep'
import { ChatMessage } from '@components/ChatMessage/ChatMessage'
import { ChatDataTestId } from '@typedef/testIDs'
import React from 'react'

interface Props {
  step: TextToChoiceStep
  submittedValue: TextToChoiceSubmitValue
}

export const SubmittedTextToChoiceStep = ({ step, submittedValue }: Props) => {
  if (!submittedValue.userMessage) {
    return null
  }

  return (
    <ChatMessage
      stepId={step.id}
      user={true}
      useUserMessageStyles={true}
      dataTestId={ChatDataTestId.TEXT_TO_CHOICE_SUBMITTED_STEP}
      message={submittedValue.userMessage}
      shouldShowAvatar={false}
      shouldAnimate={true}
    />
  )
}
