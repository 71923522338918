import React, { useCallback, useEffect, useMemo, useState } from 'react'
import isNil from 'lodash/isNil'
import ChatMultiInputComponent from './ChatMultiInputComponent'
import { ChatMessage } from '../ChatMessage/ChatMessage'
import { ChatDataTestId, MultiInputComponentSubmitRequest } from '@typedef/chatSteps'
import { evaluateExpression, isSectionVisible } from './expressions'
import { getUiVariablesFromText } from './sectionParsing'
import useTranslations from 'localisation/useTranslations'
import { TranslationKeys } from 'localisation/translations'
import { extractEmailValue, extractPhoneValue, validateNumber } from '@utils/validations'
import { SimulatorMultiInput } from '@utils/simulator/inputs'
import {
  MultiInputComponentErrors,
  MultiInputComponentInput,
  MultiInputComponentStep,
  MultiInputComponentValidationError,
  MultiInputComponentValues,
  ValidationRuleExpression,
} from '@typedef/chatSteps/MultiInputStep'

interface Props {
  data: MultiInputComponentStep
  onChipClick: (request: MultiInputComponentSubmitRequest, stepInput: SimulatorMultiInput) => void
}

const ChatMultiInputComponentMessage = ({ data, onChipClick }: Props): JSX.Element => {
  const defaultValues = useMemo(() => {
    const result: MultiInputComponentValues = {}
    Object.keys(data.inputs).forEach((key) => {
      result[key] = data.inputs[key].default ?? null
    })
    return result
  }, [data])

  const [values, setValues] = useState<MultiInputComponentValues>(defaultValues)
  const localise = useTranslations()

  const setValue = useCallback(
    (variable: string, value: string | number | null) => {
      setValues((prevValues) => ({
        ...prevValues,
        [variable]: value,
      }))
    },
    [setValues],
  )

  const removeValue = useCallback(
    (variable: string) => {
      setValues((prevValues) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [variable]: _, ...rest } = prevValues
        return rest
      })
    },
    [setValues],
  )

  const resetValues = useCallback(() => setValues(defaultValues), [defaultValues])

  // check if values should be removed or read based on visibility changes
  useEffect(() => {
    const inputVariables = Object.keys(data.inputs)
    data.sections.forEach((section) => {
      const variables = getUiVariablesFromText(section.text)
      const sectionIsVisible = isSectionVisible(section, values, inputVariables)

      variables.forEach((variable) => {
        const isVariablePresent = variable in values
        if (sectionIsVisible && !isVariablePresent) {
          setValue(variable, defaultValues[variable] ?? null)
        } else if (!sectionIsVisible && isVariablePresent) {
          removeValue(variable)
        }
      })
    })
  }, [data, defaultValues, values, setValue, removeValue])

  const allInputVariables = Object.keys(data.inputs)
  const singleFieldErrors = extractSingleFieldErrors(values, data.inputs, localise)
  const { failedRuleStringOperands, failedRules } = extractRuleValidationErrors(
    data.rules,
    values,
    allInputVariables,
  )
  const errorMessage = composeErrorMessage(failedRules, singleFieldErrors, localise)
  const errorVariables = [...Object.keys(singleFieldErrors)].concat(
    failedRuleStringOperands.filter((item) => allInputVariables.includes(item)),
  )

  return (
    <ChatMessage
      stepId={data.id}
      user={false}
      dataTestId={ChatDataTestId.MULTI_INPUT}
      message={
        <ChatMultiInputComponent
          data={data}
          values={values}
          setValue={setValue}
          resetValues={resetValues}
          errorVariables={errorVariables}
          onChipClick={onChipClick}
          errorMessage={errorMessage}
        />
      }
      shouldShowAvatar={false}
      shouldAnimate={true}
    />
  )
}

export default ChatMultiInputComponentMessage

const extractRuleValidationErrors = (
  rules: ValidationRuleExpression[],
  values: MultiInputComponentValues,
  allVariables: string[],
): { failedRuleStringOperands: string[]; failedRules: ValidationRuleExpression[] } => {
  const { failedRuleStringOperands, failedRules } = rules.reduce(
    (
      acc: {
        failedRules: ValidationRuleExpression[]
        failedRuleStringOperands: string[]
      },
      rule,
    ) => {
      if (!evaluateExpression(rule, values, allVariables)) {
        acc.failedRules.push(rule)
        if (typeof rule[1] === 'string') {
          acc.failedRuleStringOperands.push(rule[1])
        }
        if (typeof rule[2] === 'string') {
          acc.failedRuleStringOperands.push(rule[2])
        }
      }
      return acc
    },
    {
      failedRules: [],
      failedRuleStringOperands: [],
    },
  )
  return { failedRuleStringOperands: failedRuleStringOperands, failedRules }
}

const extractSingleFieldErrors = (
  values: MultiInputComponentValues,
  inputs: Record<string, MultiInputComponentInput>,
  localise: (key: TranslationKeys, values?: Record<string, string | number>) => string,
) => {
  return Object.entries(values).reduce((acc: MultiInputComponentErrors, [variable, value]) => {
    const input = inputs[variable]
    if (isNil(value) || value === '') {
      if (input?.required) {
        acc[variable] = { type: 'empty' }
      }
    } else {
      if (input?.type === 'number' && typeof value === 'number') {
        const translationKey = validateNumber(value, input.min, input.max)
        if (translationKey !== undefined) {
          acc[variable] = {
            type: 'validation',
            message: localise(translationKey, { min: input.min ?? 0, max: input.max ?? 0 }),
          }
        }
      } else if (input.type === 'email' && typeof value === 'string') {
        if (!extractEmailValue(value)) {
          acc[variable] = {
            type: 'validation',
            message: localise('enterValidEmail'),
          }
        }
      } else if (input.type === 'phone' && typeof value === 'string') {
        if (!extractPhoneValue(value)) {
          acc[variable] = {
            type: 'validation',
            message: localise('enterValidPhone'),
          }
        }
      }
    }
    return acc
  }, {})
}

const composeErrorMessage = (
  failedRules: ValidationRuleExpression[],
  fieldErrors: MultiInputComponentErrors,
  localise: (key: TranslationKeys, values?: Record<string, string | number>) => string,
) => {
  return (
    failedRules
      .map((rule) => rule[3])
      .concat(
        Object.values(fieldErrors)
          .filter(
            (error): error is MultiInputComponentValidationError => error.type === 'validation',
          )
          .map(({ message }) => message),
      )
      .concat(
        Object.values(fieldErrors).some(({ type }) => type === 'empty')
          ? [localise('selectOptionToAllFields')]
          : [],
      )
      .join('\n') || null
  )
}
