import React, { KeyboardEvent, useState } from 'react'
import { useParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import useTranslations from '../../../localisation/useTranslations'
import { ChatDataTestId } from '@typedef/testIDs'
import {
  TextToChoiceStep as TextToChoiceStepType,
  TextToChoiceSubmitValue,
} from '@typedef/chatSteps/TextToChoiceStep'
import { StepType } from '@typedef/chatSteps'
import { useChatParams } from '@store/selectors'
import { reportError } from '@utils/errorReporting'
import { ChatUrlParams } from '@constants/routes'
import { useAppSelector } from '@store/index'
import { usePostTextToChoiceMutation } from '@store/api'
import { SimulatorOptions } from '@utils/simulator/inputs'
import {
  ErrorMessage,
  SimpleInputContainer,
  StyledTextField,
} from '@containers/ChatInput/ChatInput'
import { SubmitButton } from '@containers/ChatInput/SubmitButton'
import { TEXTAREA_INPUT_CHAR_LIMIT } from '@constants/input'

export interface Props {
  step: TextToChoiceStepType
  sendValue: (value: TextToChoiceSubmitValue, stepInput?: SimulatorOptions, label?: string) => void
}

export const TextToChoiceTextInput = ({ step, sendValue }: Props) => {
  const chatParams = useChatParams()
  const { token } = useParams<ChatUrlParams>()
  const chatSteps = useAppSelector((state) => state.chat.steps)
  const stepId = chatSteps[chatSteps.length - 1].id
  const stateId = chatParams?.stateId ?? ''
  const options = step.options
  const [postTextToChoice, { isLoading }] = usePostTextToChoiceMutation()

  const [errorMsg, setErrorMsg] = useState<string | undefined>()

  const onSubmit = ({ selectedChoiceId, userMessage }: TextToChoiceSubmitValue) => {
    const choiceIndex = options.findIndex((option) => option.value === selectedChoiceId)

    sendValue(
      {
        selectedChoiceId,
        userMessage,
      },
      options.length > 1
        ? {
            type: StepType.OPTIONS_STEP, // TODO: NEG-1075 this is going to be T2C step type
            value: {
              choiceIndex,
            },
          }
        : undefined,
    )
  }

  const onTextSubmit = async (userInput: string) => {
    try {
      const { selectedChoiceId } = await postTextToChoice({
        userInput,
        token,
        stepId,
        stateId,
      }).unwrap()

      if (selectedChoiceId) {
        onSubmit({ selectedChoiceId, userMessage: userInput })
      }

      throw new Error('No choice was returned')
    } catch (e) {
      reportError(e instanceof Error ? e.message : 'No error message')
      console.log(e)
      setErrorMsg('Something went wrong. Please try again.')
    }
  }

  return (
    <>
      <TextInput
        onTextSubmit={onTextSubmit}
        minChars={step.minCharacterLimit}
        isLoading={isLoading}
      />
      {!!errorMsg ? <ErrorMessage text={errorMsg} /> : null}
    </>
  )
}

interface TextInputProps {
  onTextSubmit: (userInput: string) => void
  minChars: number
  isLoading: boolean
}

const TextInput = ({ onTextSubmit, minChars, isLoading }: TextInputProps) => {
  const [inputValue, setInputValue] = useState('')
  const localise = useTranslations()
  const progress = Math.round((inputValue.trim().length / minChars) * 100)

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (progress < 100 || isLoading) {
      return
    }

    if (e.key === 'Enter' && e.ctrlKey) {
      onTextSubmit(inputValue)
    }
  }

  return (
    <SimpleInputContainer>
      <StyledTextField
        value={inputValue}
        onChange={(e) => {
          if (e.target.value.length <= TEXTAREA_INPUT_CHAR_LIMIT) {
            setInputValue(e.target.value)
          }
        }}
        variant='standard'
        fullWidth
        type='text'
        inputProps={{
          inputMode: 'text',
          'data-testid': ChatDataTestId.TEXT_TO_CHOICE_TEXT_INPUT,
        }}
        InputProps={{
          disableUnderline: true,
          endAdornment:
            progress >= 100 ? null : (
              <CircularProgress
                variant='determinate'
                size={40}
                value={progress}
                sx={{
                  ml: 2,
                  my: -2,
                  transition: 'opacity 300ms ease',
                  ...(progress >= 100 ? { opacity: 0 } : {}),
                }}
              />
            ),
        }}
        placeholder={localise('typeAText')}
        autoFocus={true}
        multiline={true}
        maxRows={2}
        onKeyPress={handleKeyPress}
      />

      <SubmitButton disabled={progress < 100 || isLoading} onClick={() => onTextSubmit(inputValue)}>
        {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
      </SubmitButton>
    </SimpleInputContainer>
  )
}
