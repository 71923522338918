import React from 'react'
import { Typography } from '@mui/material'
import { CesStep as CesStepType, CesSubmitValue, ChatDataTestId } from '@typedef/chatSteps'
import { ChatMessage } from '@components/ChatMessage/ChatMessage'
import { CesStepContainer, StyledTextField } from './CesStep'
import { ScoreSelection } from './ScoreSelection'
import useTranslations from '../../../localisation/useTranslations'
import {
  CesSubmitScoreValue,
  CesWithSatisfactionSubmitScoreValue,
} from '@typedef/chatSteps/CesStep'

export const SubmittedCesStep = ({
  step,
  submittedValue,
}: {
  step: CesStepType
  submittedValue: CesSubmitValue
}) => {
  if (submittedValue.score === null) {
    // CES submit was cancelled, show nothing
    return null
  }

  return (
    <ChatMessage
      stepId={step.id}
      user={true}
      useUserMessageStyles={false}
      dataTestId={ChatDataTestId.CES_SUBMITTED_STEP}
      message={<SubmittedCesForm step={step} submittedValue={submittedValue} />}
      shouldShowAvatar={false}
      shouldAnimate={true}
    />
  )
}

interface SubmittedCesFormProps {
  step: CesStepType
  submittedValue: CesSubmitScoreValue | CesWithSatisfactionSubmitScoreValue
}

export const SubmittedCesForm = ({ step, submittedValue }: SubmittedCesFormProps) => {
  const localise = useTranslations()

  return (
    <CesStepContainer>
      {submittedValue.includeSatisfactionScore ? (
        <ScoreSelection
          question={localise('cesRatingSatisfactionQuestion')}
          onChange={noop}
          selectedScore={submittedValue.satisfactionScore}
          lowestScoreLabel={localise('cesRatingSatisfactionLowestScoreLabel')}
          highestScoreLabel={localise('cesRatingSatisfactionHighestScoreLabel')}
          testId={ChatDataTestId.CES_STEP_SATISFACTION_SCORE_BUTTON}
          disabled={true}
        />
      ) : null}
      <ScoreSelection
        question={step.questionLabel ?? localise('cesRatingQuestion')}
        onChange={noop}
        selectedScore={submittedValue.score}
        lowestScoreLabel={localise('cesRatingLowestScoreLabel')}
        highestScoreLabel={localise('cesRatingHighestScoreLabel')}
        testId={ChatDataTestId.CES_STEP_SCORE_BUTTON}
        disabled={true}
      />
      {submittedValue.comment ? (
        <Typography variant='body2' component='div' mt={1}>
          <StyledTextField
            label={localise('cesRatingAdditionalCommentQuestion')}
            variant='outlined'
            fullWidth
            onChange={noop}
            value={submittedValue.comment}
            multiline
            minRows={4}
            maxRows={4}
            inputProps={{
              'data-testid': ChatDataTestId.CES_STEP_COMMENT_INPUT,
            }}
            disabled={true}
          />
        </Typography>
      ) : null}
    </CesStepContainer>
  )
}

const noop = () => {}
