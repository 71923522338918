import React from 'react'
import { ChatMessage } from '@components/ChatMessage/ChatMessage'
import { ChatDataTestId } from '@typedef/testIDs'
import { TabularDataStep as TabularDataStepType, TabularDataSubmitValue } from '@typedef/chatSteps'

export interface Props {
  step: TabularDataStepType
  submittedValue: TabularDataSubmitValue
}

export const SubmittedTabularDataStep = ({ step, submittedValue }: Props) => {
  return (
    <ChatMessage
      stepId={step.id}
      user={true}
      dataTestId={ChatDataTestId.TABULAR_DATA_SUBMITTED_STEP}
      message={<SubmittedTabularData submittedValue={submittedValue} />}
      shouldShowAvatar={false}
      shouldAnimate={true}
    />
  )
}

interface SubmittedProps {
  submittedValue: TabularDataSubmitValue
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SubmittedTabularData = ({ submittedValue }: SubmittedProps) => {
  return null
}
