import React, { ChangeEvent, KeyboardEvent, useState } from 'react'
import { format as dateFnsFormat } from 'date-fns'
import SendIcon from '@mui/icons-material/Send'
import { Alert, Box, TextField, styled, Fade } from '@mui/material'

import {
  dateIsInRange,
  extractDateValue,
  extractNumericalValue,
  extractValidDateOrNull,
} from 'utils/validations'
import {
  ChatDataTestId,
  FileInputStep,
  FileValue,
  StepSubmitValue,
  StepType,
  SimpleUserInputStep,
} from '@typedef/chatSteps'
import { DateInput } from './DateInput'
import FileInput from './FileInput'
import { SubmitButton } from './SubmitButton'
import useTranslations from '../../localisation/useTranslations'
import { SingleNumberInput } from '@containers/ChatInput/SingleNumberInput'
import { SimulatorInput } from '@utils/simulator/inputs'
import { dateIsoFormat } from '@constants/datetime'
import { TextInput } from './TextInput'
import { PhoneEmailInput } from '@containers/ChatInput/PhoneEmailInput'
import { formatBytes } from '@containers/ChatInput/formatBytes'

interface Props {
  lastChatObject: SimpleUserInputStep | FileInputStep
  requestNextStep: (
    trigger: string,
    value: StepSubmitValue | undefined,
    stepInput: SimulatorInput,
  ) => void
  setLastUserMessage: (value: string) => void
  setInputError: (value: string) => void
  inputError: string
}

const ChatInput = ({
  lastChatObject,
  requestNextStep,
  setLastUserMessage,
  setInputError,
  inputError,
}: Props): JSX.Element => {
  const format = lastChatObject.format

  const [inputValue, setInputValue] = useState('')
  const [selectedDate, setSelectedDate] = useState<Date | null>(null)
  const [fileValue, setFileValue] = useState<FileValue | null>(null)
  const localise = useTranslations()

  const handleKeyPress = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      sendValue()
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (format !== 'file' && isNumberInput(format)) {
      if (!e.target.validity.valid) {
        return
      }
      setInputValue(e.target.value.replace(',', '.'))
    } else {
      setInputValue(e.target.value)
    }
  }

  const handleDateChange = (date: Date | null) => setSelectedDate(date)

  const sendValue = () => {
    if (validateInput()) {
      if (format === 'file') {
        submitFileValue()
      } else {
        submitStringValue()
      }
    }
  }

  const submitStringValue = (value?: string) => {
    let valueToSend: string

    if (format === 'date') {
      valueToSend = selectedDate ? dateFnsFormat(selectedDate, dateIsoFormat) : ''
    } else if (value) {
      valueToSend = value
    } else {
      valueToSend = inputValue || localise('none')
    }

    setLastUserMessage(valueToSend)
    setInputValue('')
    setSelectedDate(null)
    requestNextStep(lastChatObject.id, valueToSend, {
      type: StepType.INPUT_STEP,
      value: valueToSend,
    })
  }

  const submitFileValue = () => {
    if (fileValue) {
      setLastUserMessage(fileValue.filename)
      setFileValue(null)
      requestNextStep(lastChatObject.id, fileValue, {
        type: StepType.FILE_INPUT_STEP,
        value: fileValue,
      })
    }
  }

  const validateInput = () => {
    switch (format) {
      case 'any':
      case 'text':
      case 'textarea':
        return true
      case 'date': {
        if (extractDateValue(selectedDate) === null) {
          setInputError(localise('enterValidDate'))
          return false
        }
        const { min, max } = lastChatObject.properties ?? {}
        if (!dateIsInRange(selectedDate, min, max)) {
          setInputError(localise('dateOutOfRange'))
          return false
        }

        return true
      }
      case 'file': {
        const maxFileSize = lastChatObject?.maxFileSize ?? 10485760
        const supportedFileTypes = lastChatObject?.supportedFileTypes ?? []
        if (fileValue === null) {
          setInputError(localise('enterValidFile'))
          return false
        }

        if (fileValue.contentLength > maxFileSize) {
          setInputError(`${localise('fileTooBig')} (${formatBytes(maxFileSize)})`)
          return false
        }

        if (!supportedFileTypes.includes(fileValue.contentType)) {
          setInputError(localise('wrongFileFormat'))
          return false
        }

        return true
      }
      case undefined: {
        break
      }
      default: {
        const numericValue = extractNumericalValue(inputValue)
        if (isNaN(numericValue) || numericValue < 0) {
          setInputError(localise('enterPositiveNumber'))
          return false
        }
      }
    }
    return true
  }

  if (format === 'number') {
    const numberOptions = lastChatObject.properties
    return (
      <SingleNumberInput
        TextFieldComponent={StyledTextField}
        setLastUserMessage={setLastUserMessage}
        setInputError={setInputError}
        requestNextStep={requestNextStep}
        lastChatObjectId={lastChatObject.id}
        error={inputError}
        numberOptions={numberOptions ?? undefined}
      />
    )
  }

  if (format === 'file') {
    return (
      <>
        {!!inputError && <ErrorMessage text={inputError} />}
        <SimpleInputContainer>
          <FileInput setFileValue={setFileValue} setInputError={setInputError} />
          <SubmitButton onClick={sendValue}>
            <SendIcon />
          </SubmitButton>
        </SimpleInputContainer>
      </>
    )
  }

  if (format === 'date') {
    return (
      <>
        {!!inputError && <ErrorMessage text={inputError} />}
        <SimpleInputContainer>
          <DateInput
            TextFieldComponent={StyledDateTextField}
            selectedDate={selectedDate}
            minDate={extractValidDateOrNull(lastChatObject.properties?.min)}
            maxDate={extractValidDateOrNull(lastChatObject.properties?.max)}
            handleDateChange={handleDateChange}
            handleKeyPress={handleKeyPress}
          />
          <SubmitButton onClick={sendValue}>
            <SendIcon />
          </SubmitButton>
        </SimpleInputContainer>
      </>
    )
  }

  if (format === 'text' || format === 'textarea') {
    return (
      <TextInput
        inputValue={inputValue}
        setInputValue={setInputValue}
        sendValue={sendValue}
        lastChatObject={lastChatObject}
        error={inputError}
      />
    )
  }

  if (format === 'email' || format === 'phone') {
    return (
      <PhoneEmailInput
        format={lastChatObject.format}
        submitStringValue={submitStringValue}
        inputError={inputError}
        setInputError={setInputError}
      />
    )
  }

  return (
    <>
      {!!inputError && <ErrorMessage text={inputError} />}
      <SimpleInputContainer>
        <StyledTextField
          value={inputValue}
          onChange={handleChange}
          variant='standard'
          fullWidth
          type='text'
          inputProps={{
            pattern: format === 'any' ? undefined : '[0-9.,]*',
            inputMode: format === 'any' ? 'text' : 'decimal',
            'data-testid': ChatDataTestId.TEXT_INPUT,
          }}
          InputProps={{
            disableUnderline: true,
          }}
          placeholder={localise(format === 'any' ? 'typeAText' : 'typeANumber')}
          onKeyPress={handleKeyPress}
          error={!!inputError}
          autoFocus={true}
        />
        <SubmitButton onClick={sendValue}>
          <SendIcon />
        </SubmitButton>
      </SimpleInputContainer>
    </>
  )
}

const isNumberInput = (format?: SimpleUserInputStep['format']): boolean => {
  switch (format) {
    case 'phone':
    case 'email':
    case 'text':
    case 'textarea':
    case 'any':
      return false
    default:
      return true
  }
}

export const SimpleInputContainer = styled(Box)(({ theme }) => ({
  height: 56,
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  alignItems: 'center',
  borderRadius: '0 0 10px 10px',
  position: 'relative',
  margin: '0 auto',
  boxShadow: '0px 4px 15px 8px rgba(0, 0, 0, 0.08)',
}))

export const InputHelperAlert = styled(Alert)({
  position: 'absolute',
  bottom: 72,
  left: 0,
  right: 0,
  zIndex: 1,
  margin: '0 auto',
})

export const ErrorMessage = ({ text }: { text?: string }) => {
  return (
    <Fade in={!!text}>
      <InputHelperAlert data-testid={ChatDataTestId.ALERT} severity='error'>
        {text}
      </InputHelperAlert>
    </Fade>
  )
}

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  height: 56,
  flexDirection: 'row',
  padding: theme.spacing(1.5, 0, 1.5, 2),
  fontSize: 16,
  borderRadius: '0 0 10px 10px',
  color: '#000',

  '& .Mui-error': {
    color: theme.palette.error.main,
  },
})) as typeof TextField

const StyledDateTextField = styled(StyledTextField)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
})) as typeof TextField

export default ChatInput
