import {
  BaseQueryApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  retry,
} from '@reduxjs/toolkit/query/react'
import { captureMessage, withScope } from '@sentry/react'

import { environment } from 'environments/environment'
import { AppDispatch, RootState } from 'store'
import { isCoreBackendApiError } from './error'
import { refreshAccessToken } from '../authHooks'

declare let window: Window & {
  _API_BASE_URL_OVERRIDE?: string
}

export const REQUEST_TIMEOUT_ERROR = 'request timed out'
const RETRIABLE_ERROR_STATUS_CODES = [408, 418, 429, 500, 502, 503, 504, 520]

export const getApiBaseUrl = (): string => {
  if (window._API_BASE_URL_OVERRIDE) {
    return window._API_BASE_URL_OVERRIDE
  }

  if (environment.REACT_APP_ENV === 'development' || !environment.REACT_APP_ENV) {
    return environment.REACT_APP_API_URL
  }

  return `${window.location.protocol}//${window.location.host}/api/v1`
}

const baseQuery = fetchBaseQuery({
  baseUrl: getApiBaseUrl(),
  credentials: 'omit',
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.accessToken

    headers.set('Content-Type', 'application/json')

    if (token) {
      headers.set('Authorization', `Bearer ${token}`)
    }

    headers.set('X-Pactum-Auth-Version', '2')

    return headers
  },
  responseHandler: 'content-type',
})

export const baseQueryWithAuthAndRetry = retry(
  async (args: string | FetchArgs, api, extraOptions) => {
    const appDispatch = api.dispatch as AppDispatch
    await appDispatch(refreshAccessToken())
    return baseQuery(args, api, extraOptions)
  },
  {
    retryCondition: (error, _args, extraArgs): boolean => {
      if (extraArgs.attempt >= 5 || !shouldRetry(error)) {
        reportApiError(error, extraArgs.baseQueryApi)
        return false
      }

      return true
    },
  },
)

const shouldRetry = (error: FetchBaseQueryError): boolean => {
  return (
    (error.status === 'FETCH_ERROR' && error.error !== REQUEST_TIMEOUT_ERROR) ||
    (typeof error.status === 'number' && RETRIABLE_ERROR_STATUS_CODES.includes(error.status))
  )
}

const reportApiError = (error: FetchBaseQueryError, baseQueryApi: BaseQueryApi) => {
  let errorMessage = 'API error: '
  if (typeof error.status === 'number') {
    errorMessage += `${error.status}: ${JSON.stringify(error.data)}`
  } else if (error.status === 'PARSING_ERROR') {
    errorMessage += `${error.status}: (originalStatus: ${error.originalStatus}) ${error.error}`
  } else {
    errorMessage += JSON.stringify(error)
  }
  console.error(errorMessage)

  if (shouldReportToSentry(error)) {
    withScope((scope) => {
      scope.setExtra('fetchBaseError', error)
      scope.setExtra('endpoint', baseQueryApi.endpoint)
      scope.setExtra('queryType', baseQueryApi.type)

      captureMessage(errorMessage)
    })
  }
}

const shouldReportToSentry = (error: FetchBaseQueryError): boolean => {
  if (isCoreBackendApiError(error)) {
    const status = error.status
    const errorInternalCode = error.data.meta.internalCode

    // 500 is reported by backend anyway
    if ([403, 500].includes(status) || errorInternalCode !== undefined) {
      return false
    }

    return status >= 400
  }

  return true
}
