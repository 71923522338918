export enum ChatDataTestId {
  CHAT = 'chat',
  LOADER = 'loader',
  MESSAGE_LOADER = 'message-loader',
  AVATAR = 'avatar',
  ALERT = 'alert-msg',
  ALERT_STEP_ERROR = 'alert-msg-step-error',
  ALERT_HELP_ERROR = 'alert-help-error',
  ALERT_CHATBOX_NETWORK_ERROR = 'alert-chatbox-network-error',
  READ_ONLY_ALERT = 'supplier-link-read-only-alert',
  NEED_HELP_BUTTON = 'need-help-button',
  CLOSE_HELP_DRAWER_BUTTON = 'close-help-drawer-button',
  SEND_HELP_MESSAGE_BUTTON = 'send-help-message-button',
  HELP_FAQ_SEARCH_INPUT = 'help-faq-search-input',
  DROPDOWN_INPUT = 'chat-dropdown-input',
  DATE_INPUT = 'chat-date-input',
  NUMBER_INPUT = 'chat-number-input',
  SLIDER_INPUT = 'chat-slider-input',
  SLIDER_VALUE = 'chat-slider-value',
  TEXT_INPUT = 'chat-text-input',
  FILE_INPUT = 'chat-file-input',
  COOKIE_CONSENT = 'chat-cookie-consent',
  STATUS_BAR = 'chat-status-bar',
  MINIMIZE_MAXIMIZE_CHAT_AREA_BUTTON = 'minimize-maximize-chat-area-button',
  MULTI_CHOICE_CHECKBOXES = 'chat-multi-choice-checkboxes',
  MULTI_INPUT = 'chat-multi-input',
  MULTI_INPUT_CONTAINER = 'chat-multi-input-container',
  MULTI_INPUT_ELEMENT = 'chat-multi-input-element',
  MULTI_INPUT_NUMBER_INPUT = 'chat-multi-input-number-input',
  CES_STEP = 'chat-ces-step',
  CES_SUBMITTED_STEP = 'chat-ces-submitted-step',
  CES_STEP_SATISFACTION_SCORE_BUTTON = 'chat-ces-step-satisfaction-score-button',
  CES_STEP_SCORE_BUTTON = 'chat-ces-step-score-button',
  CES_STEP_COMMENT_INPUT = 'chat-ces-step-comment-input',
  CES_STEP_SUBMIT_BUTTON = 'chat-ces-step-submit-button',
  CES_STEP_CANCEL_BUTTON = 'chat-ces-step-cancel-button',
  TABULAR_DATA_STEP = 'chat-tabular-data-step',
  TABULAR_DATA_SUBMITTED_STEP = 'chat-tabular-data-submitted-step',
  TABULAR_DATA_SUBMIT_BUTTON = 'chat-tabular-data-submit-button',
  TABULAR_DATA_TAB_SELECTION_BUTTON = 'chat-tabular-data-tab-selection-button',
  HISTORY = 'chat-history',
  OPTION_BUTTONS_CONTAINER = 'chat-option-buttons-container',
  OPTION_BUTTON = 'chat-option-button',
  CHAT_SUBMIT_BUTTON = 'chat-submit-button',
  CURRENT_STEP_NODE = 'current-chat-step',
  TEXT_TO_CHOICE_STEP = 'chat-text-to-choice-step',
  TEXT_TO_CHOICE_SUBMITTED_STEP = 'chat-text-to-choice-submitted-step',
  TEXT_TO_CHOICE_TEXT_INPUT = 'chat-text-to-choice-text-input',
  TEXT_TO_CHOICE_BUTTON_CONTAINER = 'chat-text-to-choice-button-container',
}
