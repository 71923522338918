import { ChatStep } from '@typedef/chatSteps'

export const getStepIdCount = (displayedSteps: ChatStep[]): Record<string, number> => {
  const stepIdCount: Record<string, number> = {}
  let previousStepId = ''

  for (const step of displayedSteps) {
    if (step.id === previousStepId) {
      // this means that previous step was input step and now we have output step
      continue
    }

    stepIdCount[step.id] ??= 0
    stepIdCount[step.id] += 1
    previousStepId = step.id
  }

  return stepIdCount
}

export const getBranchingUrl = (stateUrl: string, stepId: string, stepCount: number): string => {
  let url = `${stateUrl}/stepId/${stepId}`

  if (stepCount > 1) {
    url += `/stepCount/${stepCount}`
  }

  return url
}
