import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Alert, Box, Stack, styled, Tab, Tabs } from '@mui/material'
import { TabularDataTab as TabularDataTabType } from '@pactum/core-backend-types'

import { ChatMessage } from '@components/ChatMessage/ChatMessage'
import { ChatDataTestId } from '@typedef/testIDs'
import {
  StepType,
  TabularDataStep as TabularDataStepType,
  SubmittedTabularDataStep as SubmittedTabularDataStepType,
  TabularDataSubmitValue,
} from '@typedef/chatSteps'
import { SimulatorTabularData } from '@utils/simulator/inputs'
import { SubmittedTabularDataStep } from './SubmittedTabularDataStep'
import { TabularDataTab } from '@components/InputSteps/TabularDataStep/TabularDataTab'
import { ChatUrlParams } from '@constants/routes'
import { useAppSelector } from '@store/index'
import { useChatParams, useIsReadOnlyChatSession } from '@store/selectors'
import { useTabularDataSubmitMutation, useTabularDataUpdateMutation } from '@store/api'
import ChipButton from '@components/ChipButton/ChipButton'
import useTranslations from '../../../localisation/useTranslations'
import { HTML } from '@components/HTML'
import { isQueryErrorResponse } from '@store/api/error'

export interface TabularDataStepProps {
  step: TabularDataStepType | SubmittedTabularDataStepType
  sendValue: (
    value: TabularDataSubmitValue,
    stepInput: SimulatorTabularData,
    label?: string,
  ) => void
}

export const TabularDataStep = ({ step, sendValue }: TabularDataStepProps) => {
  if ('submittedValue' in step) {
    return (
      <SubmittedTabularDataStep step={step.originalStep} submittedValue={step.submittedValue} />
    )
  }

  return (
    <ChatMessage
      stepId={step.id}
      user={false}
      dataTestId={ChatDataTestId.TABULAR_DATA_STEP}
      message={<TabularData step={step} sendValue={sendValue} />}
      shouldShowAvatar={false}
      shouldAnimate
      fullWidth
    />
  )
}

interface TableProps {
  step: TabularDataStepType
  sendValue: TabularDataStepProps['sendValue']
}

const TabularData = ({ step, sendValue }: TableProps) => {
  const chatParams = useChatParams()
  const { token } = useParams<ChatUrlParams>()
  const localise = useTranslations()
  const chatSteps = useAppSelector((state) => state.chat.steps)
  const [updateData, { isLoading: isUpdateInProgress }] = useTabularDataUpdateMutation()
  const [submitData, { isLoading: isSubmitting }] = useTabularDataSubmitMutation()
  const stepId = chatSteps[chatSteps.length - 1].id
  const stateId = chatParams?.stateId ?? ''
  const tabs = step.tabs
  const [selectedTab, setSelectedTab] = useState<TabularDataTabType['id']>(tabs[0].id)
  const [submitError, setSubmitError] = useState('')

  const isReadOnlyView = useIsReadOnlyChatSession()

  const onSubmit = async () => {
    if (isUpdateInProgress) {
      return
    }

    const response = await submitData({
      token,
      stepId,
      stateId,
    })

    if (isQueryErrorResponse(response)) {
      setSubmitError(localise('generalError'))
      return
    }

    const { tabularDataError, tabularData } = response.data
    if (tabularDataError) {
      setSubmitError(tabularDataError)
    } else {
      const submitValue = {
        submittedData: tabularData ?? [],
      }
      sendValue(submitValue, { type: StepType.TABULAR_DATA_STEP, value: submitValue })
    }
  }

  return (
    <Box>
      <Stack gap={2} data-table-submitting={isSubmitting}>
        {tabs.length === 1 ? (
          <TabularDataTab
            key={selectedTab}
            tab={tabs.find((t) => t.id === selectedTab)!}
            token={token}
            stepId={stepId}
            stateId={stateId}
            updateData={updateData}
          />
        ) : (
          <Box>
            <TabularDataTab
              key={selectedTab}
              tab={tabs.find((t) => t.id === selectedTab)!}
              token={token}
              stepId={stepId}
              stateId={stateId}
              updateData={updateData}
            />
            <Tabs
              value={selectedTab}
              onChange={(e, newValue) => setSelectedTab(newValue)}
              variant='scrollable'
              scrollButtons='auto'
              sx={{
                mt: 0.5,
                minHeight: 0,
                '& .MuiTabs-indicator': { backgroundColor: 'transparent' },
                '& .MuiTabs-scrollButtons.Mui-disabled': {
                  opacity: 0.3,
                },
              }}
            >
              {tabs.map((tab) => (
                <StyledTab
                  key={tab.id}
                  value={tab.id}
                  label={tab.label}
                  data-testid={ChatDataTestId.TABULAR_DATA_TAB_SELECTION_BUTTON}
                  data-tabid={tab.id}
                />
              ))}
            </Tabs>
          </Box>
        )}
      </Stack>
      {submitError ? (
        <Alert severity='error' onClose={() => setSubmitError('')} sx={{ mt: 0.5 }}>
          <HTML markup={submitError} />
        </Alert>
      ) : null}
      <Box mt={2}>
        <ChipButton
          data-testid={ChatDataTestId.TABULAR_DATA_SUBMIT_BUTTON}
          color='primary'
          label={localise('submit')}
          onClick={onSubmit}
          disabled={isUpdateInProgress || isReadOnlyView}
        />
      </Box>
    </Box>
  )
}

const StyledTab = styled(Tab)(({ theme }) => ({
  minHeight: 0,
  padding: theme.spacing(1, 4),
  marginLeft: theme.spacing(0.5),
  color: '#6C6C6C',
  fontSize: '12px',
  textTransform: 'none',
  borderBottom: '1px solid #BFBFBF',
  borderLeft: '1px solid #BFBFBF',
  borderRight: '1px solid #BFBFBF',
  borderRadius: '0 0 4px 4px',

  ':first-of-type': {
    marginLeft: 0,
  },

  '&.Mui-selected': {
    color: theme.palette.text.primary,
    fontWeight: 'bold',
    backgroundColor: '#EEEEEE',
  },
}))
