import { ChatDataTestId, StepSubmitValue, StepType } from '@typedef/chatSteps'
import { NumberInputProperties } from '@typedef/chatSteps/SimpleUserInputStep'
import useTranslations from '../../localisation/useTranslations'
import { useNumberLocale } from '@hooks/useNumberLocale'
import React, { KeyboardEvent, useState } from 'react'
import {
  ErrorMessage,
  InputHelperAlert,
  SimpleInputContainer,
} from '@containers/ChatInput/ChatInput'
import { SubmitButton } from '@containers/ChatInput/SubmitButton'
import SendIcon from '@mui/icons-material/Send'
import { formatNumber, NumberInput, parseNumber } from '@containers/ChatInput/NumberInput'
import { validateNumber } from '@utils/validations'
import { SimulatorInput } from '@utils/simulator/inputs'
import { TextField } from '@mui/material'

interface Props {
  TextFieldComponent: typeof TextField
  lastChatObjectId: string
  requestNextStep: (
    trigger: string,
    value: StepSubmitValue | undefined,
    userMessage: SimulatorInput,
  ) => void
  setLastUserMessage: (value: string) => void
  setInputError: (value: string) => void
  error?: string
  numberOptions?: NumberInputProperties
}

export const SingleNumberInput = ({
  TextFieldComponent,
  lastChatObjectId,
  requestNextStep,
  setLastUserMessage,
  setInputError,
  error,
  numberOptions,
}: Props) => {
  const localise = useTranslations()
  const numberLocale = useNumberLocale()

  const [currentValue, setCurrentValue] = useState<number | null>(null)
  const handleSubmit = () => {
    if (currentValue === null) {
      setInputError('Invalid number value')
      return
    }

    // reformat and parse the number to ensure that maximumFractionDigits is applied to submitted value
    // when submitting with "enter" and onBlur() event hasn't triggered yet
    const reformattedCurrentValue = parseNumber(
      formatNumber(currentValue, numberLocale, numberOptions?.numberFormat),
      numberLocale,
      numberOptions?.numberFormat,
    )
    if (isNaN(reformattedCurrentValue)) {
      setInputError('Invalid number value')
      return
    }

    if (numberOptions) {
      const translationKey = validateNumber(
        reformattedCurrentValue,
        numberOptions.min,
        numberOptions.max,
      )
      if (translationKey !== undefined) {
        setInputError(
          localise(translationKey, { min: numberOptions.min ?? 0, max: numberOptions.max ?? 0 }),
        )
        return
      }
    }
    setLastUserMessage(reformattedCurrentValue.toString())
    requestNextStep(lastChatObjectId, reformattedCurrentValue, {
      type: StepType.INPUT_STEP,
      value: reformattedCurrentValue,
    })
  }

  const submitOnEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return (
    <>
      {currentValue !== null && !error && (
        <InputHelperAlert icon={false} severity='info'>
          {localise('numberInput.helperText', {
            value: formatNumber(currentValue, numberLocale, numberOptions?.numberFormat),
          })}
        </InputHelperAlert>
      )}
      {error && <ErrorMessage text={error} />}
      <SimpleInputContainer>
        <NumberInput
          TextFieldComponent={TextFieldComponent}
          variant='standard'
          numberLocale={numberLocale}
          value={currentValue}
          onValidInput={setCurrentValue}
          onKeyPress={submitOnEnter}
          fullWidth
          inputProps={{
            'data-testid': ChatDataTestId.NUMBER_INPUT,
          }}
          InputProps={{
            disableUnderline: true,
          }}
          autoFocus
          formatOptions={numberOptions?.numberFormat}
          placeholderTranslationKey={'numberInput.placeholder'}
        />
        <SubmitButton disabled={currentValue === null} onClick={handleSubmit}>
          <SendIcon />
        </SubmitButton>
      </SimpleInputContainer>
    </>
  )
}
