import React, { useState } from 'react'

import { Box, Drawer, styled } from '@mui/material'
import Help from '@mui/icons-material/Help'
import Cancel from '@mui/icons-material/Cancel'

import { HELP_DRAWER_WIDTH } from 'constants/styles'
import { ActionButton } from './styled'
import useTranslations from 'localisation/useTranslations'
import { ChatDataTestId } from '@typedef/chatSteps'
import { HelpDrawerContent } from '@components/HelpDrawer/HelpDrawerContent'

export const HelpDrawer = (): JSX.Element | null => {
  const [helpVisible, setHelpVisible] = useState(false)
  const localise = useTranslations()

  return (
    <>
      <ActionButton
        variant='contained'
        color='secondary'
        disableElevation
        onClick={() => setHelpVisible(true)}
        data-testid={ChatDataTestId.NEED_HELP_BUTTON}
      >
        <HelpIcon />
        {localise('helpDrawer.openButton')}
      </ActionButton>
      <StyledDrawer
        anchor='right'
        open={helpVisible}
        onClose={() => setHelpVisible(false)}
        ModalProps={{ keepMounted: true }}
      >
        <CloseHelpDrawerWrapper>
          <ActionButton
            variant='outlined'
            color='primary'
            disableElevation
            onClick={() => setHelpVisible(false)}
            data-testid={ChatDataTestId.CLOSE_HELP_DRAWER_BUTTON}
          >
            <CancelIcon />
            {localise('helpDrawer.closeButton')}
          </ActionButton>
        </CloseHelpDrawerWrapper>
        <HelpDrawerContent />
      </StyledDrawer>
    </>
  )
}

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.common.white,
    width: HELP_DRAWER_WIDTH,
    maxWidth: '100%',
    padding: theme.spacing(3, 5),
  },
}))

const CloseHelpDrawerWrapper = styled(Box)(({ theme }) => ({
  textAlign: 'right',
  marginBottom: theme.spacing(4),
}))

const HelpIcon = styled(Help)(({ theme }) => ({
  fontSize: 18,
  color: '#9F7800',
  marginRight: theme.spacing(0.5),
}))

const CancelIcon = styled(Cancel)(({ theme }) => ({
  fontSize: 18,
  color: '#BDBDBD',
  marginRight: theme.spacing(0.5),
}))
