import React, { useEffect, useMemo, useRef } from 'react'
import debounce from 'lodash/debounce'
import { SerializedError } from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'

import { Box, BoxProps, Fade, Stack, styled } from '@mui/material'

import { ChatMessage } from 'components/ChatMessage/ChatMessage'
import { TextLoader } from '@components/TextLoader/TextLoader'
import { ChatMultiInputComponentMessage } from 'components/ChatMultiInputComponent'
import { ChatOptions } from 'components/ChatOptions/ChatOptions'
import { ChatMultiChoice } from 'components/ChatMultiChoice'

import { shouldSkipChatStep } from 'utils/chat'
import {
  ChatDataTestId,
  ChatStep,
  isCesStep,
  isMultiInputComponentStep,
  isMultipleChoiceStep,
  isOptionsStep,
  isSubmittedCesStep,
  isSubmittedTextToChoiceStep,
  isTabularDataStep,
  isTextToChoiceStep,
  isUserStep,
  StepSubmitValue,
  StepType,
} from '@typedef/chatSteps'
import { useAppSelector } from 'store'
import { useDisplayedChatSteps } from 'store/selectors'
import { CesStep } from '@components/InputSteps'
import ChatHistory from '@containers/ChatBox/ChatHistory'
import { CHATBOX_MAX_WIDTH } from '@constants/styles'
import { BotAvatar } from '@components/BotAvatar'
import { ChatBoxErrorMessage } from '@containers/ChatBox/ChatBoxErrorMessage'
import { SimulatorInput } from '@utils/simulator/inputs'
import { TabularDataStep } from '@components/InputSteps/TabularDataStep/TabularDataStep'
import { getStepIdCount } from '@utils/branchingUrl'
import { TextToChoiceOptions } from '@components/InputSteps/TextToChoice/TextToChoiceOptions'

interface Props {
  lastUserMessage?: string
  requestNextStep: (
    trigger: string,
    inputValue?: StepSubmitValue,
    stepInput?: SimulatorInput,
  ) => void
  retryPreviousStep: () => void
  chatStepError?: FetchBaseQueryError | SerializedError
  setLastUserMessage: (value: string) => void
  isLoading: boolean
  isBottomInputShown: boolean
  isText2ChoiceLlmEnabled: boolean
}

export const ChatBox = ({
  lastUserMessage,
  isLoading,
  setLastUserMessage,
  requestNextStep,
  retryPreviousStep,
  chatStepError,
  isBottomInputShown,
  isText2ChoiceLlmEnabled,
}: Props): JSX.Element => {
  const {
    steps: chatSteps,
    timeoutError: nextStepTimeoutError,
    authInfo: { readOnly, isMainLink },
  } = useAppSelector((state) => state.chat)
  const chatBoxContainerRef = useRef(null)
  const displayedChatSteps = useDisplayedChatSteps()
  const lastChatObject: ChatStep | undefined = chatSteps[chatSteps.length - 1]
  const stepIdCount = getStepIdCount(displayedChatSteps)
  const isInputOrErrorVisible =
    isBottomInputShown || !!chatStepError || !!nextStepTimeoutError || (isMainLink && readOnly)

  const scrollToLastMessage = () => {
    if (messageRef && messageRef.current) {
      messageRef.current.scrollIntoView({ block: 'end', behavior: 'smooth' })
    }
  }

  const debouncedAppHeightAdjustment = useMemo(() => debounce(scrollToLastMessage, 300), [])

  useEffect(() => {
    window.addEventListener('resize', debouncedAppHeightAdjustment)
    scrollToLastMessage()

    return () => window.removeEventListener('resize', debouncedAppHeightAdjustment)
  }, [debouncedAppHeightAdjustment])

  const messageRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    let timeoutIdentifier: ReturnType<typeof setTimeout>

    if (messageRef && messageRef.current) {
      timeoutIdentifier = setTimeout(() => scrollToLastMessage(), 50)
    }

    return () => clearTimeout(timeoutIdentifier)
  })

  const sendValue = (value: StepSubmitValue, stepInput?: SimulatorInput, label?: string) => {
    if (lastChatObject) {
      setLastUserMessage(label ?? '')
      requestNextStep(lastChatObject.id, value, stepInput)
    }
  }

  const shouldShowLoader = () => {
    if (lastChatObject && shouldSkipChatStep(lastChatObject)) {
      return true
    }

    if (lastChatObject && 'user' in lastChatObject && 'message' in lastChatObject) {
      const isEndNode = 'end' in lastChatObject && lastChatObject.end
      return lastChatObject.user && lastChatObject.message && !isEndNode
    }

    return false
  }

  const shouldShowTemporarySubmitValue = () => {
    return (
      (isUserStep(lastChatObject) ||
        isOptionsStep(lastChatObject) ||
        isMultipleChoiceStep(lastChatObject)) &&
      !(lastChatObject && 'message' in lastChatObject && lastChatObject.message)
    )
  }

  return (
    <>
      <Container isInputOrErrorVisible={isInputOrErrorVisible} ref={chatBoxContainerRef}>
        <Box m='0 auto' pt={3.25} maxWidth={CHATBOX_MAX_WIDTH}>
          <ChatHistory />
          {isLoading || shouldShowLoader() ? (
            <MessageLoader
              showBotAvatar={
                displayedChatSteps.length === 0 ||
                isUserStep(displayedChatSteps[displayedChatSteps.length - 1]) ||
                !!lastUserMessage
              }
            />
          ) : (
            <>
              {isTextToChoiceStep(lastChatObject) &&
              !isSubmittedTextToChoiceStep(lastChatObject) &&
              (lastChatObject.displayManualChoices || !isText2ChoiceLlmEnabled) ? (
                <TextToChoiceOptions step={lastChatObject} sendValue={sendValue} />
              ) : null}
              {isCesStep(lastChatObject) && !isSubmittedCesStep(lastChatObject) ? (
                <CesStep step={lastChatObject} sendValue={sendValue} />
              ) : null}
              {isTabularDataStep(lastChatObject) ? (
                <TabularDataStep step={lastChatObject} sendValue={sendValue} />
              ) : null}
              {isMultiInputComponentStep(lastChatObject) ? (
                <ChatMultiInputComponentMessage
                  data={lastChatObject}
                  onChipClick={(request, userInput) => sendValue(request, userInput)}
                />
              ) : null}
              {isMultipleChoiceStep(lastChatObject) ? (
                <ChatMultiChoice
                  step={lastChatObject}
                  sendValue={sendValue}
                  chatBoxContainerRef={chatBoxContainerRef}
                />
              ) : null}
              {isOptionsStep(lastChatObject) ? (
                <ChatOptions
                  choices={lastChatObject.options}
                  onChipClick={(choice, index) => {
                    sendValue(
                      choice.value,
                      lastChatObject.options.length > 1
                        ? {
                            type: StepType.OPTIONS_STEP,
                            value: {
                              choiceIndex: index,
                            },
                          }
                        : undefined,
                      choice.label,
                    )
                  }}
                />
              ) : null}
              {shouldShowTemporarySubmitValue() && !!lastUserMessage ? (
                <ChatMessage
                  stepId={lastChatObject?.id}
                  key='temp-user-message'
                  user={true}
                  message={lastUserMessage}
                  shouldShowAvatar={false}
                  shouldAnimate={true}
                />
              ) : null}
            </>
          )}
          <Box
            component='span'
            mt={1}
            sx={{ display: 'block' }}
            ref={messageRef}
            data-current-step-id={lastChatObject?.id}
            data-current-step-count={stepIdCount?.[lastChatObject?.id]}
            data-testid={ChatDataTestId.CURRENT_STEP_NODE}
          />
        </Box>
      </Container>
      <ChatBoxErrorMessage chatStepError={chatStepError} retryPreviousStep={retryPreviousStep} />
    </>
  )
}

const MessageLoader = ({ showBotAvatar }: { showBotAvatar: boolean }) => (
  <Fade in={true} timeout={500}>
    <Box pl={1} pb={1} sx={{ overflow: 'hidden' }} data-testid={ChatDataTestId.MESSAGE_LOADER}>
      {showBotAvatar ? <BotAvatar /> : null}
      <MessageLoaderWrapper direction='row'>
        <TextLoader />
      </MessageLoaderWrapper>
    </Box>
  </Fade>
)
const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isInputOrErrorVisible',
})<BoxProps & { isInputOrErrorVisible: boolean }>(({ theme, isInputOrErrorVisible }) => ({
  position: 'relative',
  margin: '0 auto',
  height: isInputOrErrorVisible ? 'calc(100% - 72px)' : '100%',
  overflowY: 'auto',
  backgroundColor: theme.palette.common.white,

  '@media print': {
    height: 'auto',
  },
}))

const MessageLoaderWrapper = styled(Stack)(({ theme }) => ({
  margin: theme.spacing(1),

  [theme.breakpoints.up('lg')]: {
    marginLeft: theme.spacing(3),
  },
}))
